.App {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* margin-top: 50px; */
}
.App.empty {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-height: 100vh; */
  max-height: 100vh;
  overflow:auto;
  /* background-color: blueviolet; */
}


.icon{
  padding-left: 10px;
  align-self: center;
  justify-self: center;
}


.note-space{
  /* background-color: blue; */
  width: 90%;
}
.title {
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  outline: 0px;

  width: 70%;
  margin-bottom: 10px;
  margin-top: 25px
}
.title.hug {
  width: auto;
  margin-bottom: 20px;
}
.editor {
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  outline: 0px;

  /* width: 70%; */
  min-height: 70vh;
  padding-bottom: 80px;

  max-width: 70ch;
}



.button-header{
  padding-bottom: 10px;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: chartreuse; */
  width: 90%;
}
.button-grp {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: blue; */
}
.button-grp.sidebar {
  width: 100%;
  /* background-color: #0099E0; */
  padding: 0px;
}
.button {
  background-color: rgb(255, 229, 246);
  transition: all 0.2s ease;
  border: 0;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
}
.button:hover {
    background-color: hsla(300,60%,67%,0.5);
    cursor: pointer;
}
.button.delete {
  background-color: #ffffff;
  color: rgb(241, 35, 93);
}
.button.delete:hover {
  /* background-color: rgb(255, 229, 246); */
  /* border: 1px solid rgb(241, 35, 93); */
  -webkit-box-shadow:inset 0px 0px 0px 1px #f00;
  -moz-box-shadow:inset 0px 0px 0px 1px #f00;
  box-shadow:inset 0px 0px 0px 1px #f00;
}
.button.newnote {
  width: 100%;
  justify-self: 'center';
  align-self: 'center';
  background-color: rgb(255, 229, 246, 0);
  margin: 0px;
  /* transition: all 0.2s ease; */
  /* border: 1px solid rgb(0, 0, 0); */
  font-size: 16px;
}
.button.newnote:hover {
  text-decoration: underline;
  cursor: pointer;
}
.button.icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-bottom: 10px;
  background-color:hsla(306,77%,89%,1);
  background-image:
  radial-gradient(at 26% 62%, hsla(95,88%,78%,1) 0px, transparent 50%),
  radial-gradient(at 84% 41%, hsla(330,83%,77%,1) 0px, transparent 50%),
  radial-gradient(at 88% 14%, hsla(58,60%,73%,1) 0px, transparent 50%),
  radial-gradient(at 45% 53%, hsla(300,60%,67%,1) 0px, transparent 50%),
  radial-gradient(at 29% 66%, hsla(102,80%,73%,1) 0px, transparent 50%);
}
.button.icon.plus {
  margin-bottom: 0px;
  width: 45px;
  height: 45px;
  background-image: none;
  background-color: rgb(255, 229, 246, 0);
  border: 1px solid rgb(0, 0, 0);
}
.button.icon.chevron {
  background-image: none;
  background-color: rgb(255, 229, 246, 0);
  margin: 0px;
  padding: 0px;
  width: 20px;
  height: 20px;
  align-self: flex-start;
}
.chevron-wrapper {
  /* background-color: #0099E0; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px
}
.logo {
  font-size: 16px;
  font-weight: bold;
  width: 20px;
}


.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}
.profile-pic:hover {
  cursor: pointer;
}

.sidebar{
  /* background-color: red; */
  transition: width 0.2s ease;

  padding: 20px;
  background-color:rgb(255, 229, 246);
  margin: 5%;
  border-radius: 20px;

  /* max-width: 300px; */
  /* width: 30%; */
  /* min-width: 150; */
  width: 250px;
  height: 90%;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;

  background-color:hsla(306,77%,89%,1);
  background-image:
  radial-gradient(at 26% 62%, hsla(95,88%,78%,1) 0px, transparent 50%),
  radial-gradient(at 84% 41%, hsla(330,83%,77%,1) 0px, transparent 50%),
  radial-gradient(at 88% 14%, hsla(58,60%,73%,1) 0px, transparent 50%),
  radial-gradient(at 45% 53%, hsla(300,60%,67%,1) 0px, transparent 50%),
  radial-gradient(at 29% 66%, hsla(102,80%,73%,1) 0px, transparent 50%);
}
.sidebar.hidden {
  background-color: #ffffff;
  background-image: none;
  width: 50px;
}
.all-notes {
  width: 100%;
  height: 85vh;
  overflow-y: scroll;
}
.note{
  /*pointer on hover*/
  width: 100%;
  /* padding-top: 10px; */
  padding-bottom: 20px;
}
.note:hover {
  cursor: pointer;
}
  
.selected{
  text-decoration: underline;
}
.all-notes-title {
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  outline: 0px;

  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.last-saved{
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  outline: 0px;
}






.sidebar-enter {
  transform: translateX(-100%);
  width: 250px;
  opacity: 0;
  /* flex: 1; */
  /* display: flex; */
}
.sidebar-enter-active {
  transform: translateX(0);
  opacity: 1;
  width: 250px;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.sidebar-exit {
  width: 50px;
  transform: translateX(0);
  opacity: 1;
}
.sidebar-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  width: 50px;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
















.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: rgb(255, 229, 246);
  /* border: 1px solid hsla(300,60%,67%,0.5); */
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgb(248, 207, 234);
}
.react-toggle--checked .react-toggle-track {
  background-color: hsla(300,60%,67%,0.5);
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: hsla(300,60%,67%,0.7);
}
.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid rgb(255, 229, 246);
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: hsla(300,60%,67%,0.5);
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}